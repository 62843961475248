import React from "react"
import { Row, Col, Icon, Button, Card, Typography } from "antd"
import { useIntl } from "gatsby-plugin-intl"
import reactLogo from "../../images/react-logo.svg"
import serverlessLogo from "../../images/serverless-logo.svg"
import customDevelopmentStyles from "./solutions.module.css"

const { Title } = Typography

export default ({ openContactForm }) => {
  const intl = useIntl()

  return (
    <Row
      type="flex"
      justify="space-around"
      align="middle"
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
    >
      <Col xs={24} sm={20} lg={12} xl={12}>
        <div
          style={{
            width: "100%",
            // height: "400px",
            minHeight: "300px",
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
            margin: "10px 0",
            padding: 20,
            textAlign: "center",
          }}
          className={customDevelopmentStyles.customDevelopmentCard}
        >
          <Title
            level={2}
            style={{
              fontFamily: "Poppins",
              fontSize: "40px",
              textAlign: "center",
              color: "#fff",
            }}
          >
            {intl.formatMessage({ id: "customDevelopment" })}
          </Title>
          <p style={{ color: "#fff" }}>
            {intl.formatMessage({ id: "customDevelopmentDescription" })}
          </p>
          <Title
            level={2}
            style={{
              fontFamily: "Poppins",
              fontSize: "40px",
              textAlign: "center",
              color: "#fff",
            }}
          >
            360 {intl.formatMessage({ id: "customDevelopmentPrice" })}
          </Title>
          <Button
            onClick={() =>
              openContactForm(intl.formatMessage({ id: "customDevelopment" }))
            }
            type="primary"
          >
            {intl.formatMessage({ id: "customDevelopmentCTA" })}
          </Button>
        </div>
      </Col>
      <Col xs={24} sm={20} lg={12} xl={12}>
        <div
          style={{
            width: "100%",
            minHeight: "400px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <Card
            style={{
              width: "100%",
              borderRadius: 10,
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            >
              <Col xs={24} sm={4}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                >
                  <img
                    src={reactLogo}
                    style={{ width: "100%", maxWidth: 70 }}
                    alt="react logo"
                  />
                </div>
              </Col>
              <Col xs={24} sm={20}>
                <Title
                  level={3}
                  style={{
                    fontFamily: "Poppins",
                  }}
                >
                  {intl.formatMessage({ id: "customDevelopmentReactTitle" })}
                </Title>
                <p>
                  {intl.formatMessage({
                    id: "customDevelopmentReactDescription",
                  })}
                </p>
              </Col>
            </Row>
          </Card>
          <Row type="flex" justify="center">
            <Col>
              <Icon
                type="plus-circle"
                style={{ marginTop: 20, marginBottom: 20 }}
              />
            </Col>
          </Row>
          <Card
            style={{
              width: "100%",
              borderRadius: 10,
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
              // margin: "0 10px 10px 10px",
            }}
          >
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            >
              <Col xs={24} sm={4}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                >
                  <img
                    src={serverlessLogo}
                    style={{ width: "100%", maxWidth: 70 }}
                    alt="serverless logo"
                  />
                </div>
              </Col>
              <Col xs={24} sm={20}>
                <Title
                  level={3}
                  style={{
                    fontFamily: "Poppins",
                  }}
                >
                  {intl.formatMessage({
                    id: "customDevelopmentServerlessTitle",
                  })}
                </Title>
                <p>
                  {intl.formatMessage({
                    id: "customDevelopmentServerlessDescription",
                  })}
                </p>
              </Col>
            </Row>
          </Card>
        </div>
      </Col>
    </Row>
  )
}
