import React, { useState } from "react"
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Typography,
  Icon,
  Divider,
  Alert,
  Result,
} from "antd"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"
import sendEmail from "../utils/sendEmail"
import contactFormStyles from "./contactForm.module.css"
import { validateEmail } from "../utils/validate"

const { Option } = Select
const { Title } = Typography

export default ({ currentSolution, toggleModal, currentLocale }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [solution, setSolution] = useState(currentSolution || undefined)
  const [error, setError] = useState({ status: false, message: "" })
  const [success, setSuccess] = useState({ status: false, message: "" })
  const [loading, setLoading] = useState(false)

  const intl = useIntl()

  const submitForm = async e => {
    e.preventDefault()

    if (error.status) setError({ status: false, message: "" })

    if (!solution)
      return setError({
        status: true,
        message: intl.formatMessage({ id: "solutionErrorMessage" }),
      })

    if (!name)
      return setError({
        status: true,
        message: intl.formatMessage({ id: "nameErrorMessage" }),
      })

    const isEmailValid = validateEmail(email)
    if (!isEmailValid)
      return setError({
        status: true,
        message: intl.formatMessage({ id: "emailErrorMessage" }),
      })

    setLoading(true)

    try {
      const status = await sendEmail(name, email, solution)
      if (status === "success") {
        setSuccess({
          status: true,
          message: intl.formatMessage({ id: "emailSuccessTitle" }),
        })

        setName("")
        setEmail("")
        setSolution(undefined)
      } else {
        setError({
          status: true,
          message: intl.formatMessage({ id: "emailSentErrorMessage" }),
        })
      }
      setLoading(false)
    } catch (e) {
      setError({
        status: true,
        message: intl.formatMessage({ id: "emailSentErrorMessage" }),
      })
      setLoading(false)
    }
  }

  return (
    <div>
      <Title
        level={2}
        style={{
          fontFamily: "Poppins",
          textAlign: "center",
        }}
      >
        {intl.formatMessage({ id: "letsTalk" })} <Icon type="message" />
      </Title>
      {success.status ? (
        <Result
          status="success"
          title={success.message}
          subTitle={intl.formatMessage({ id: "emailSuccessSubtitle" })}
          extra={[
            <Button
              onClick={() => toggleModal(false)}
              type="primary"
              key="console"
            >
              {intl.formatMessage({ id: "emailSuccessButton" })}
            </Button>,
          ]}
        />
      ) : (
        <>
          <p style={{ textAlign: "center" }}>
            {intl.formatMessage({ id: "modalDescription" })}
          </p>
          {error.status && (
            <Alert
              description={error.message}
              message={intl.formatMessage({ id: "goneWrong" })}
              type="error"
              showIcon
              closable
              afterClose={() => setError({ status: false, message: "" })}
            />
          )}
          <Form onSubmit={submitForm}>
            <Row gutter={16}>
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  label={intl.formatMessage({ id: "formName" })}
                  className={contactFormStyles.formItem}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: "formNamePlaceholder",
                    })}
                    onChange={e => setName(e.target.value)}
                    maxLength={320}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  label={intl.formatMessage({ id: "formEmail" })}
                  className={contactFormStyles.formItem}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: "formEmailPlaceholder",
                    })}
                    onChange={e => setEmail(e.target.value)}
                    maxLength={320}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              className={contactFormStyles.formItem}
              label={intl.formatMessage({ id: "formWhatSolution" })}
            >
              <Select
                defaultActiveFirstOption={false}
                placeholder={intl.formatMessage({
                  id: "formWhatSolutionPlaceholder",
                })}
                value={solution}
                onChange={value => setSolution(value)}
              >
                <Option value={intl.formatMessage({ id: "sofwareAsAService" })}>
                  {intl.formatMessage({ id: "sofwareAsAService" })}
                </Option>
                <Option value={intl.formatMessage({ id: "cloudComputing" })}>
                  {intl.formatMessage({ id: "cloudComputing" })}
                </Option>
                <Option value={intl.formatMessage({ id: "customDevelopment" })}>
                  {intl.formatMessage({ id: "customDevelopment" })}
                </Option>
                <Option value={intl.formatMessage({ id: "consulting" })}>
                  {intl.formatMessage({ id: "consulting" })}
                </Option>
              </Select>
            </Form.Item>
            <Divider />
            <p style={{ textAlign: "center" }}>
              {intl.formatMessage({ id: "contactUsVia" })}
            </p>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    loading={loading}
                    block
                    type="primary"
                    htmlType="submit"
                  >
                    <Icon type="mail" />
                    {intl.formatMessage({ id: "email" })}
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
            <div style={{ textAlign: "center", marginTop: 5 }}>
              <small>
                {intl.formatMessage({ id: "privacyMessage" })}
                <Link to="/privacidad">
                  {intl.formatMessage({ id: "privacyTitle" })}
                </Link>
              </small>
            </div>
          </Form>
        </>
      )}
    </div>
  )
}
