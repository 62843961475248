import axios from "axios";

export default async function(name, email, solution) {
  try {
    const response = await axios.post(
      "https://tu1fx1bno9.execute-api.us-east-1.amazonaws.com/dev/sendcontactemail",
      {
        name: name || "",
        solution: solution || "",
        email: email || ""
      }
    );
    console.log(response.data.status);
    return response.data.status;
  } catch (e) {
    console.log(e.message);
    return "error";
  }
}
