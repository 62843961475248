import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { Row, Col, Icon, Button, Card, Typography } from "antd"
import consultingStyles from "./solutions.module.css"

const { Title } = Typography

export default ({ openContactForm }) => {
  const intl = useIntl()

  return (
    <Row
      type="flex"
      justify="space-around"
      align="middle"
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
    >
      <Col xs={24} sm={20} lg={12} xl={12}>
        <div
          style={{
            width: "100%",
            // height: "400px",
            minHeight: "300px",
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
            margin: "10px 0",
            padding: 20,
            textAlign: "center",
          }}
          className={consultingStyles.consultingCard}
        >
          <Title
            level={2}
            style={{
              fontFamily: "Poppins",
              fontSize: "40px",
              textAlign: "center",
              color: "#fff",
            }}
          >
            {intl.formatMessage({ id: "consulting" })}
          </Title>
          <p style={{ color: "#fff" }}>
            {intl.formatMessage({ id: "consultingDescription" })}
          </p>
          <Title
            level={2}
            style={{
              fontFamily: "Poppins",
              fontSize: "40px",
              textAlign: "center",
              color: "#fff",
            }}
          >
            360 {intl.formatMessage({ id: "consultingPrice" })}
          </Title>
          <Button
            onClick={() =>
              openContactForm(intl.formatMessage({ id: "consulting" }))
            }
            type="primary"
          >
            {intl.formatMessage({ id: "consultingCTA" })}
          </Button>
        </div>
      </Col>
      <Col xs={24} sm={20} lg={12} xl={12}>
        <div
          style={{
            width: "100%",
            minHeight: "400px",
            backgroundColor: "#fff",
            // boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <Card
            style={{
              width: "100%",
              borderRadius: 10,
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
              // margin: "0 10px 10px 10px",
            }}
          >
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            >
              <Col xs={24} sm={4}>
                <Icon
                  type="instagram"
                  style={{ width: "100%", fontSize: 60, marginBottom: 10 }}
                />
              </Col>
              <Col xs={24} sm={20}>
                <Title
                  level={3}
                  style={{
                    fontFamily: "Poppins",
                  }}
                >
                  {intl.formatMessage({ id: "digitalMarketing" })}
                </Title>
                <p>
                  {intl.formatMessage({ id: "digitalMarketingDescription" })}
                </p>
              </Col>
            </Row>
          </Card>
          <Row type="flex" justify="center">
            <Col>
              <Icon
                type="plus-circle"
                style={{ marginTop: 20, marginBottom: 20 }}
              />
            </Col>
          </Row>
          <Card
            style={{
              width: "100%",
              borderRadius: 10,
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
              // margin: 10,
            }}
          >
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            >
              <Col xs={24} sm={4}>
                <Icon
                  type="cloud-server"
                  style={{ width: "100%", fontSize: 60, marginBottom: 10 }}
                />
              </Col>
              <Col xs={24} sm={20}>
                <Title
                  level={3}
                  style={{
                    fontFamily: "Poppins",
                  }}
                >
                  {intl.formatMessage({ id: "cloudSolutions" })}
                </Title>
                <p>{intl.formatMessage({ id: "cloudSolutionsDescription" })}</p>
              </Col>
            </Row>
          </Card>
          <Row type="flex" justify="center">
            <Col>
              <Icon
                type="plus-circle"
                style={{ marginTop: 20, marginBottom: 20 }}
              />
            </Col>
          </Row>
          <Card
            style={{
              width: "100%",
              borderRadius: 10,
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
              // margin: 10,
            }}
          >
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            >
              <Col xs={24} sm={4}>
                <Icon
                  type="laptop"
                  style={{ width: "100%", fontSize: 60, marginBottom: 10 }}
                />
              </Col>
              <Col xs={24} sm={20}>
                <Title
                  level={3}
                  style={{
                    fontFamily: "Poppins",
                  }}
                >
                  {intl.formatMessage({ id: "digitalSolutions" })}
                </Title>
                <p>
                  {intl.formatMessage({ id: "digitalSolutionsDescription" })}
                </p>
              </Col>
            </Row>
          </Card>
        </div>
      </Col>
    </Row>
  )
}
