import React, { useState } from "react"
import { Form, Select, Input, Button, Alert, Result } from "antd"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"
import contactFormStyles from "./contactForm.module.css"
import { validateEmail } from "../utils/validate"
import sendEmail from "../utils/sendEmail"

const { Option } = Select

export default () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [solution, setSolution] = useState(undefined)
  const [error, setError] = useState({ status: false, message: "" })
  const [success, setSuccess] = useState({ status: false, message: "" })
  const [loading, setLoading] = useState(false)

  const intl = useIntl()

  const handleSubmit = async e => {
    e.preventDefault()

    if (error.status) setError({ status: false, message: "" })

    if (!solution)
      return setError({
        status: true,
        message: intl.formatMessage({ id: "solutionErrorMessage" }),
      })

    if (!name)
      return setError({
        status: true,
        message: intl.formatMessage({ id: "nameErrorMessage" }),
      })

    const isEmailValid = validateEmail(email)
    if (!isEmailValid)
      return setError({
        status: true,
        message: intl.formatMessage({ id: "emailErrorMessage" }),
      })

    setLoading(true)

    try {
      const status = await sendEmail(name, email, solution)
      if (status === "success") {
        setSuccess({
          status: true,
          message: intl.formatMessage({ id: "emailSuccessTitle" }),
        })

        setName("")
        setEmail("")
        setSolution(undefined)
      } else {
        setError({
          status: true,
          message: intl.formatMessage({ id: "emailSentErrorMessage" }),
        })
      }
      setLoading(false)
    } catch (e) {
      setError({
        status: true,
        message: intl.formatMessage({ id: "emailSentErrorMessage" }),
      })
      setLoading(false)
    }
  }

  return (
    <>
      {success.status ? (
        <Result
          status="success"
          title={success.message}
          subTitle={intl.formatMessage({ id: "emailSuccessSubtitle" })}
        />
      ) : (
        <Form layout="vertical" onSubmit={handleSubmit}>
          {error.status && (
            <Alert
              description={error.message}
              message={intl.formatMessage({ id: "goneWrong" })}
              type="error"
              showIcon
              closable
              afterClose={() => setError({ status: false, message: "" })}
            />
          )}
          <Form.Item
            label={intl.formatMessage({ id: "formName" })}
            className={contactFormStyles.formItem}
          >
            <Input
              placeholder={intl.formatMessage({ id: "formNamePlaceholder" })}
              onChange={e => setName(e.target.value)}
              maxLength={320}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: "formEmail" })}
            className={contactFormStyles.formItem}
          >
            <Input
              placeholder={intl.formatMessage({ id: "formEmailPlaceholder" })}
              onChange={e => setEmail(e.target.value)}
              maxLength={320}
            />
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: "formWhatSolution" })}>
            <Select
              defaultActiveFirstOption={false}
              placeholder={intl.formatMessage({
                id: "formWhatSolutionPlaceholder",
              })}
              value={solution}
              onChange={value => setSolution(value)}
            >
              <Option value={intl.formatMessage({ id: "sofwareAsAService" })}>
                {intl.formatMessage({ id: "sofwareAsAService" })}
              </Option>
              <Option value={intl.formatMessage({ id: "cloudComputing" })}>
                {intl.formatMessage({ id: "cloudComputing" })}
              </Option>
              <Option value={intl.formatMessage({ id: "customDevelopment" })}>
                {intl.formatMessage({ id: "customDevelopment" })}
              </Option>
              <Option value={intl.formatMessage({ id: "consulting" })}>
                {intl.formatMessage({ id: "consulting" })}
              </Option>
            </Select>
          </Form.Item>
          <Form.Item className={contactFormStyles.formItem}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              block
              size="large"
              icon="mail"
            >
              {intl.formatMessage({ id: "contactViaEmail" })}
            </Button>
          </Form.Item>
          <Form.Item className={contactFormStyles.formItem}>
            <div style={{ textAlign: "center", marginTop: 5 }}>
              <small>
                {intl.formatMessage({ id: "privacyMessage" })}
                <Link to="/privacidad">
                  {intl.formatMessage({ id: "privacyTitle" })}
                </Link>
              </small>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  )
}
