import React, { useState } from "react";
import { Layout, Menu, Icon, Row, Col, Popover } from "antd";
import { Link as GLink } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-intl";
import Footer from "./footer";
import lightLogo from "../images/2019-02-logo-monocromatico-blanco-digitalway.svg";
import layoutStyles from "./layout.module.css";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}

const { Header, Content } = Layout;
const LayoutApp = ({ children, currentLocale }) => {
  const [responsiveMenuIcon, setResponsiveMenuIcon] = useState("menu");
  const intl = useIntl();

  const handleMenuOpen = isOpen => {
    if (isOpen) {
      setResponsiveMenuIcon("close");
    } else {
      setResponsiveMenuIcon("menu");
    }
  };

  return (
    <>
      <Header className={layoutStyles.header}>
        <Row type='flex' justify='space-around' align='middle'>
          <Col xs={24} sm={24} md={4}>
            <div style={{ width: "100%", textAlign: "center" }}>
              <img src={lightLogo} alt='logo' width='120px' />
              <Popover
                placement='bottomRight'
                onVisibleChange={visible => handleMenuOpen(visible)}
                content={
                  <div style={{ width: "80vw" }}>
                    <Menu
                      theme='dark'
                      mode='vertical'
                      defaultSelectedKeys={["2"]}
                    >
                      <Menu.Item key='Technologies'>
                        <Link
                          to={`/#${intl.formatMessage({
                            id: "technologiesId"
                          })}`}
                        >
                          {intl.formatMessage({ id: "headerTechnologies" })}
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='Solutions'>
                        <Link
                          to={`/#${intl.formatMessage({ id: "solutionsId" })}`}
                        >
                          {intl.formatMessage({ id: "headerSolutions" })}
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='how it works'>
                        <Link
                          to={`/#${intl.formatMessage({ id: "howItWorksId" })}`}
                        >
                          {intl.formatMessage({ id: "headerHowItWorks" })}
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='language'>
                        {currentLocale === "es" ? (
                          <GLink to='/en/'>
                            <Icon type='global' />
                            en
                          </GLink>
                        ) : (
                          <GLink to='/'>
                            <Icon type='global' />
                            es
                          </GLink>
                        )}
                      </Menu.Item>
                    </Menu>
                  </div>
                }
                trigger='click'
              >
                <Icon
                  type={responsiveMenuIcon}
                  className={layoutStyles.menuIcon}
                />
              </Popover>
            </div>
          </Col>
          <Col xs={24} sm={20}>
            <Menu
              theme='dark'
              mode='horizontal'
              defaultSelectedKeys={["2"]}
              className={layoutStyles.menu}
            >
              <Menu.Item key='Technologies'>
                <Link to={`/#${intl.formatMessage({ id: "technologiesId" })}`}>
                  {intl.formatMessage({ id: "headerTechnologies" })}
                </Link>
              </Menu.Item>
              <Menu.Item key='Solutions'>
                <Link to={`/#${intl.formatMessage({ id: "solutionsId" })}`}>
                  {intl.formatMessage({ id: "headerSolutions" })}
                </Link>
              </Menu.Item>
              <Menu.Item key='how it works'>
                <Link to={`/#${intl.formatMessage({ id: "howItWorksId" })}`}>
                  {intl.formatMessage({ id: "headerHowItWorks" })}
                </Link>
              </Menu.Item>
              <Menu.Item key='language'>
                {currentLocale === "es" ? (
                  <GLink to='/en/'>
                    <Icon type='global' />
                    en
                  </GLink>
                ) : (
                  <GLink to='/'>
                    <Icon type='global' />
                    es
                  </GLink>
                )}
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
      </Header>
      <Content>{children}</Content>
      <div>
        <svg
          viewBox='0 0 1440 120'
          width='100%'
          height='100px'
          preserveAspectRatio='none'
          fill='#f5f5f5'
        >
          <path d='M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z' />
        </svg>
      </div>
      <Footer />
    </>
  );
};

export default LayoutApp;
