import React, { useEffect, useState } from "react";
import bannerStyles from "./principalBanner.module.css";
import Particles from "react-particles-js";

export default ({ children }) => {
  const [particlesNumber, setParticlesNumber] = useState(30);

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth >= 990) setParticlesNumber(90);
  }, []);

  return (
    <>
      <Particles
        params={{
          particles: {
            number: {
              value: particlesNumber,
              density: {
                enable: true,
                value_area: 800
              }
            }
          }
        }}
        className={bannerStyles.particles}
        canvasClassName={bannerStyles.canvasParticles}
      />
      <div className={bannerStyles.banner}>
        <div style={{ height: "80px" }} />
        <div className={bannerStyles.bannerContainer}>{children}</div>
        <div style={{ height: "100px" }} />
      </div>
    </>
  );
};
