import React, { useState } from "react"
import { useIntl, IntlContextConsumer } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import PrincipalBanner from "../components/principalBanner"
import ContactForm from "../components/contactForm"
import Container from "../components/container"
// import PremiumWebsites from "../components/solutions/premiumWebsites"
import SaaS from "../components/solutions/saas"
import CustomDevelopment from "../components/solutions/customDevelopment"
import Consulting from "../components/solutions/consulting"
import Emoji from "../components/emoji"
import ModalContactForm from "../components/modalContactForm"
import {
  Typography,
  Row,
  Col,
  Tooltip,
  Timeline,
  Icon,
  Divider,
  Button,
  Card,
  Tabs,
  Modal,
} from "antd"
import Head from "../components/head"
import indexStyles from "./index.module.css"
import reactLogo from "../images/react-logo.svg"
import wordpressLogo from "../images/Wordpress-Logo.svg"
import woocommerceLogo from "../images/woocommerce-logo.svg"
import gatsbyLogo from "../images/gatsby-logo.svg"
import firebaseLogo from "../images/firebase-logo.svg"
import awsLogo from "../images/aws-logo.svg"
import serverlessLogo from "../images/serverless-logo.svg"
import cloudflareLogoV from "../images/cloudflare-logo-v.svg"
import CloudComputing from "../components/solutions/cloudComputing"

const { Title } = Typography
const TabPane = Tabs.TabPane

export default () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [currentSolution, setCurrentSolution] = useState("")
  const intl = useIntl()

  const openContactForm = solution => {
    trackCustomEvent({
      category: "Contact Form Modal",
      action: "Open modal",
      label: solution || "CTA Banner",
    })

    setCurrentSolution(solution)
    setModalVisible(true)
  }

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <>
          <Layout currentLocale={currentLocale}>
            <Head title={intl.formatMessage({ id: "pageTitle" })} />
            <PrincipalBanner>
              <Row type="flex" justify="space-around" align="middle">
                <Col xs={22} lg={10} xl={10}>
                  <Title
                    style={{
                      color: "#fff",
                      fontFamily: "Poppins",
                      textAlign: "center",
                      fontSize: "7.5vh",
                    }}
                  >
                    {intl.formatMessage({ id: "principalBanner" })}
                  </Title>
                </Col>
                <Col xs={22} lg={10} xl={10}>
                  <div className={indexStyles.square}>
                    <Title
                      level={2}
                      style={{
                        fontFamily: "Poppins",
                        textAlign: "center",
                      }}
                    >
                      {intl.formatMessage({ id: "bannerFormTitle" })}
                    </Title>
                    <ContactForm currentLocale={currentLocale} />
                  </div>
                </Col>
              </Row>
            </PrincipalBanner>
            <div style={{ marginTop: -100 }}>
              <svg
                viewBox="0 0 1440 120"
                width="100%"
                height="100px"
                preserveAspectRatio="none"
                fill="#fff"
              >
                <path d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z" />
              </svg>
            </div>
            <Container>
              <div id={intl.formatMessage({ id: "technologiesId" })}>
                <Title
                  level={2}
                  style={{
                    fontFamily: "Poppins",
                    textAlign: "center",
                    fontSize: "40px",
                  }}
                >
                  {intl.formatMessage({ id: "technologiesTitle" })}
                </Title>
                <p style={{ textAlign: "center", marginBottom: "60px" }}>
                  {intl.formatMessage({ id: "technologiesDescription" })}
                </p>
                <Row
                  gutter={{ xs: 12, sm: 26, md: 28, lg: 36 }}
                  type="flex"
                  justify="space-around"
                  align="middle"
                >
                  <Col xs={3} lg={3} xl={3} className={indexStyles.techLogo}>
                    <Tooltip title="React JS">
                      <img src={reactLogo} width="100%" alt="react-logo" />
                    </Tooltip>
                  </Col>
                  <Col xs={3} lg={3} xl={3} className={indexStyles.techLogo}>
                    <Tooltip title="WordPress">
                      <img
                        src={wordpressLogo}
                        width="100%"
                        alt="wordpress-logo"
                      />
                    </Tooltip>
                  </Col>
                  <Col xs={3} lg={3} xl={3} className={indexStyles.techLogo}>
                    <Tooltip title="WooCommerce">
                      <img
                        src={woocommerceLogo}
                        width="100%"
                        alt="woocommerce-logo"
                      />
                    </Tooltip>
                  </Col>
                  <Col xs={3} lg={3} xl={3} className={indexStyles.techLogo}>
                    <Tooltip title="Gatsby JS">
                      <img src={gatsbyLogo} width="100%" alt="gatsby-logo" />
                    </Tooltip>
                  </Col>
                  <Col xs={3} lg={3} xl={3} className={indexStyles.techLogo}>
                    <Tooltip title="Firebase">
                      <img
                        src={firebaseLogo}
                        width="100%"
                        alt="firebase-logo"
                      />
                    </Tooltip>
                  </Col>
                  <Col xs={3} lg={3} xl={3} className={indexStyles.techLogo}>
                    <Tooltip title="Amazon Web Services">
                      <img src={awsLogo} width="100%" alt="aws-logo" />
                    </Tooltip>
                  </Col>
                  <Col xs={3} lg={3} xl={3} className={indexStyles.techLogo}>
                    <Tooltip title="Serverless Framework">
                      <img
                        src={serverlessLogo}
                        width="100%"
                        alt="serverless-logo"
                      />
                    </Tooltip>
                  </Col>
                  <Col xs={3} lg={3} xl={3} className={indexStyles.techLogo}>
                    <Tooltip title="Cloudflare">
                      <img
                        src={cloudflareLogoV}
                        width="100%"
                        alt="cloudflare-logo"
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </div>
              <p style={{ textAlign: "center" }}>
                {intl.formatMessage({ id: "manyMore" })}
              </p>
              <Divider className={indexStyles.divider} />
              <div id={intl.formatMessage({ id: "solutionsId" })}>
                <Title
                  level={2}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "40px",
                    textAlign: "center",
                  }}
                >
                  {intl.formatMessage({ id: "solutionsTitle" })}
                </Title>
              </div>
              <Tabs defaultActiveKey="1" size="large">
                <TabPane
                  tab={intl.formatMessage({ id: "sofwareAsAService" })}
                  key="1"
                >
                  <SaaS openContactForm={openContactForm} />
                </TabPane>
                <TabPane
                  tab={intl.formatMessage({ id: "cloudComputing" })}
                  key="2"
                >
                  <CloudComputing openContactForm={openContactForm} />
                </TabPane>
                <TabPane
                  tab={intl.formatMessage({ id: "customDevelopment" })}
                  key="3"
                >
                  <CustomDevelopment openContactForm={openContactForm} />
                </TabPane>
                <TabPane tab={intl.formatMessage({ id: "consulting" })} key="4">
                  <Consulting openContactForm={openContactForm} />
                </TabPane>
              </Tabs>
              <Divider className={indexStyles.divider} />
              <Row type="flex" justify="center">
                <Col span={24}>
                  <div id={intl.formatMessage({ id: "howItWorksId" })}>
                    <Title
                      level={2}
                      style={{
                        fontFamily: "Poppins",
                        textAlign: "center",
                        fontSize: "40px",
                      }}
                    >
                      {intl.formatMessage({ id: "processTitle" })}
                    </Title>
                    <Timeline mode="alternate">
                      <Timeline.Item
                        dot={
                          <Icon
                            type="caret-down"
                            style={{ fontSize: "16px" }}
                          />
                        }
                      >
                        {intl.formatMessage({ id: "ideaOrProblemToSolve" })}
                        <Emoji symbol="🤔" />
                      </Timeline.Item>
                      <Timeline.Item
                        dot={
                          <Icon
                            type="caret-down"
                            style={{ fontSize: "16px" }}
                          />
                        }
                      >
                        <Emoji symbol="📈" />{" "}
                        {intl.formatMessage({ id: "exploreTechnologies" })}
                      </Timeline.Item>
                      <Timeline.Item
                        dot={
                          <Icon
                            type="caret-down"
                            style={{ fontSize: "16px" }}
                          />
                        }
                        style={{ paddingBottom: 0 }}
                      >
                        <Emoji symbol="💡" />{" "}
                        {intl.formatMessage({ id: "provideBestSolution" })}
                      </Timeline.Item>
                    </Timeline>
                  </div>
                </Col>
                <Col>
                  <Emoji
                    symbol={"✔"}
                    size="25px"
                    style={{ textAlign: "center" }}
                  />
                </Col>
              </Row>
              <Divider className={indexStyles.divider} />
              <Card
                style={{
                  width: "100%",
                  borderRadius: 10,
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
                  textAlign: "center",
                }}
                className={indexStyles.callToAction}
              >
                <Title
                  level={3}
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "40px",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {intl.formatMessage({ id: "CTATitle" })}
                </Title>
                <p style={{ color: "#f5f5f5" }}>
                  {intl.formatMessage({ id: "CTADescription" })}
                </p>
                <Button
                  onClick={() => openContactForm("")}
                  type="primary"
                  size="large"
                >
                  {intl.formatMessage({ id: "CTALink" })}
                </Button>
              </Card>
            </Container>
          </Layout>
          <Modal
            centered
            width={700}
            visible={modalVisible}
            destroyOnClose
            footer={false}
            onCancel={() => setModalVisible(false)}
            bodyStyle={{ backgroundColor: "#f5f5f5", borderRadius: "10px" }}
            className="modal-class"
          >
            <ModalContactForm
              currentSolution={currentSolution}
              toggleModal={setModalVisible}
              currentLocale={currentLocale}
            />
          </Modal>
        </>
      )}
    </IntlContextConsumer>
  )
}
